<template>
  <div class="content">
    <router-tabs v-model="activeName" :tab-arr="tabs"
                 :query="$route.query" :params="$route.params"
                 :max="7" />
    <router-view />
  </div>
</template>

<script>
import RouterTabs from '@/base/components/Default/RouterTabs'
export default {
  components: { RouterTabs },
  data() {
    return {
      activeName: this.$route.name,
      tabs: [
        { label: "基本信息", name: "EditUnit", params: true },
        {
          label: "会员信息",
          name: "UnitMemberMessage",
          params: true,
        },
        { label: "我的企业", name: "UnitCompanyList", params: true },
        { label: "我的资源", name: "UnitResources", params: true },
        { label: "我的活动", name: "UnitActivity", params: true },
        { label: "我的留言", name: "UnitLeaveMsgList", params: true },
        { label: "我的订单", name: "UnitOrder", params: true },

        { label: "职务记录", name: "UnitPositionRecode", params: true },
        { label: "被举报记录", name: "UnitReportRecode", params: true },
        { label: "转账记录", name: "UnitCredential", params: true },
        { label: "邀请收益", name: "UnitInvitationRevenue", params: true },
        { label: "等级积分记录", name: "UnitIncomeRecords", params: true },
      ]
    }
  }
}
</script>

<style scoped>

</style>
